import React, { useState, useContext } from "react";
import { useHistory } from "react-router-dom";

import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	FormControl,
	InputLabel,
	makeStyles,
	MenuItem,
	Select
} from "@material-ui/core";

import api from "../../services/api";
import { AuthContext } from "../../context/Auth/AuthContext";
import ButtonWithSpinner from "../ButtonWithSpinner";
import { i18n } from "../../translate/i18n";
import toastError from "../../errors/toastError";

const useStyles = makeStyles((theme) => ({
	autoComplete: {
		width: 300
	},
	maxWidth: {
		width: "100%",
	},
	buttonColorError: {
		color: theme.palette.error.main,
		borderColor: theme.palette.error.main,
	},
}));

const AcceptTicketWithouSelectQueue = ({ modalOpen, onClose, ticketId }) => {
	const history = useHistory();
	const classes = useStyles();
	const [selectedQueue, setSelectedQueue] = useState('');
	const [loading, setLoading] = useState(false);
	const { user } = useContext(AuthContext);

	const handleClose = () => {
		onClose();
		setSelectedQueue("");
	};

	const handleUpdateTicketStatus = async (queueId) => {
		setLoading(true);
		try {
			await api.put(`/tickets/${ticketId}`, {
				status: "open",
				userId: user?.id || null,
				queueId: queueId
			});

			setLoading(false);
			history.push(`/tickets/${ticketId}`);
			handleClose();
		} catch (err) {
			setLoading(false);
			toastError(err);
		}
	};

	return (
		<>
			<Dialog open={modalOpen} onClose={handleClose}>
				<DialogTitle id="form-dialog-title">
					{i18n.t("ticketsList.acceptModal.title")}
				</DialogTitle>

				<DialogContent dividers>
					<FormControl variant="outlined" className={classes.maxWidth}>
						<InputLabel>
							{i18n.t("ticketsList.acceptModal.queue")}
						</InputLabel>

						<Select
							value={selectedQueue}
							className={classes.autoComplete}
							onChange={(e) => setSelectedQueue(e.target.value)}
							label={i18n.t("ticketsList.acceptModal.queue")}
						>
							<MenuItem value={''}>&nbsp;</MenuItem>
							{user.queues.map((queue) => (
								<MenuItem key={queue.id} value={queue.id}>{queue.name}</MenuItem>
							))}
						</Select>
					</FormControl>
				</DialogContent>

				<DialogActions>
					<Button
						onClick={handleClose}
						className={classes.buttonColorError}
						disabled={loading}
						variant="outlined"
					>
						{i18n.t("ticketsList.buttons.cancel")}
					</Button>

					<ButtonWithSpinner
						variant="contained"
						type="button"
						disabled={(selectedQueue === "")}
						onClick={() => handleUpdateTicketStatus(selectedQueue)}
						color="primary"
						loading={loading}
					>
						{i18n.t("ticketsList.buttons.accept")}
					</ButtonWithSpinner>
				</DialogActions>
			</Dialog>
		</>
	);
};

export default AcceptTicketWithouSelectQueue;