import React, { useState, useEffect } from "react";
import openSocket from "../../services/socket-io";

import {
    Container,
    makeStyles,
    Paper,
    OutlinedInput,
    Typography,
    InputAdornment,
    IconButton
} from "@material-ui/core";

import CopyToClipboard from "../../components/CopyToClipboard";

import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
    root: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(8, 8, 3),
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        padding: theme.spacing(2),
        display: "flex",
        alignItems: "center",
        marginBottom: 12,
    }
}));

const Tokens = () => {
    const classes = useStyles();

    const [tokens, setTokens] = useState([]);

    useEffect(() => {
        const fetchSession = async () => {
            try {
                const { data } = await api.get("/tokens");
                setTokens(data);
            } catch (err) {
                toastError(err);
            }
        };
        fetchSession();
    }, []);

    useEffect(() => {
        const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

        socket.on("tokens", data => {
            if (data.action === "update") {
                setTokens(prevState => {
                    const aux = [...prevState];
                    const tokensIndex = aux.findIndex(s => s.key === data.tokens.key);
                    aux[tokensIndex].value = data.tokens.value;
                    return aux;
                });
            }
        });

        return () => {
            socket.disconnect();
        };
    }, []);

    const getTokensValue = key => {
        const { value } = tokens.find(s => s.key === key);
        return value;
    };

    return (
        <div className={classes.root}>
            <Container maxWidth="sm">
                <Typography variant="body2" gutterBottom>
                    {i18n.t("mainDrawer.listItems.apitokens")}
                </Typography>

                <Paper className={classes.paper}>
                    <OutlinedInput
                        id="api-token-setting"
                        readOnly
                        label="Token Api"
                        margin="dense"
                        variant="outlined"
                        fullWidth
                        value={tokens && tokens.length > 0 && getTokensValue("userApiToken")}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton>
                                    <CopyToClipboard content={tokens && tokens.length > 0 && getTokensValue("userApiToken")} />
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </Paper>

            </Container>
        </div>
    );
};

export default Tokens;