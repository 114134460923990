import React, { useState, useEffect } from "react";
import openSocket from "../../services/socket-io";
import { makeStyles } from "@material-ui/core/styles";

import {
	Paper,
	Typography,
	Container,
	Select,
	Button,
	Tooltip,
	TextField,
	MenuItem,
	Radio,
	FormControlLabel,
	FormControl,
	RadioGroup,
	Grid,
	Tabs,
	Tab
} from "@material-ui/core";

import Title from "../../components/Title";
import { toast } from "react-toastify";
import api from "../../services/api";
import { i18n } from "../../translate/i18n.js";
import toastError from "../../errors/toastError";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(8, 8, 3),
	},
	paper: {
		padding: theme.spacing(2),
		display: "flex",
		alignItems: "center",
		marginBottom: 12,

	},
	settingOption: {
		marginLeft: "auto",
	},
	margin: {
		margin: theme.spacing(1),
	},
	selectContainer: {
		width: "100%",
		textAlign: "left",
	},
	tab: {
		background: "#fff5f8",
		borderRadius: 4,
		width: "100%",
		"& .MuiTab-wrapper": {
			color: "#FE3E6D"
		},
		"& .MuiTabs-flexContainer": {
			justifyContent: "center"
		}
	},
}));

const Settings = () => {
	const classes = useStyles();

	const [settings, setSettings] = useState([]);

	useEffect(() => {
		const fetchSession = async () => {
			try {
				const { data } = await api.get("/settings");
				setSettings(data);
			} catch (err) {
				toastError(err);
			}
		};
		fetchSession();
	}, []);

	const restartpm2 = async () => {
		try {
			await api.post('/restartpm2');
		} catch (err) {
			toastError(err);
		}
	}

	const updatesoftware = async () => {
		try {
			await api.post('/updatesoftware');
		} catch (err) {
			toastError(err);
		}
	}

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);

		socket.on("settings", data => {
			if (data.action === "update") {
				setSettings(prevState => {
					const aux = [...prevState];
					const settingIndex = aux.findIndex(s => s.key === data.setting.key);
					aux[settingIndex].value = data.setting.value;
					return aux;
				});
			}
		});

		return () => {
			socket.disconnect();
		};
	}, []);

	const handleChangeSetting = async e => {
		const selectedValue = e.target.value;
		const settingKey = e.target.name;

		try {
			await api.put(`/settings/${settingKey}`, {
				value: selectedValue,
			});
			toast.success(i18n.t("settings.success"));
		} catch (err) {
			toastError(err);
		}
	};

	const getSettingValue = key => {
		const { value } = settings.find(s => s.key === key);
		return value;
	};

	return (
		<div className={classes.root}>
			<Container className={classes.container} >

				<Title>{i18n.t("settings.title")}</Title>

				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.timeCreateNewTicket.name")}
					</Typography>

					<Select
						margin="dense"
						variant="outlined"
						id="timeCreateNewTicket-setting"
						name="timeCreateNewTicket"
						value={settings && settings.length > 0 && getSettingValue("timeCreateNewTicket")}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<MenuItem value="10"> {i18n.t("settings.settings.timeCreateNewTicket.options.10")} </MenuItem>
						<MenuItem value="30"> {i18n.t("settings.settings.timeCreateNewTicket.options.30")} </MenuItem>
						<MenuItem value="60"> {i18n.t("settings.settings.timeCreateNewTicket.options.60")} </MenuItem>
						<MenuItem value="300"> {i18n.t("settings.settings.timeCreateNewTicket.options.300")} </MenuItem>
						<MenuItem value="1800"> {i18n.t("settings.settings.timeCreateNewTicket.options.1800")} </MenuItem>
						<MenuItem value="3600"> {i18n.t("settings.settings.timeCreateNewTicket.options.3600")} </MenuItem>
						<MenuItem value="7200"> {i18n.t("settings.settings.timeCreateNewTicket.options.7200")} </MenuItem>
						<MenuItem value="21600"> {i18n.t("settings.settings.timeCreateNewTicket.options.21600")} </MenuItem>
						<MenuItem value="43200"> {i18n.t("settings.settings.timeCreateNewTicket.options.43200")} </MenuItem>
						<MenuItem value="86400"> {i18n.t("settings.settings.timeCreateNewTicket.options.86400")} </MenuItem>
						<MenuItem value="172800"> {i18n.t("settings.settings.timeCreateNewTicket.options.172800")} </MenuItem>
						<MenuItem value="604800"> {i18n.t("settings.settings.timeCreateNewTicket.options.604800")} </MenuItem>
					</Select>
				</Paper>

				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.call.name")}
					</Typography>

					<Select
						margin="dense"
						variant="outlined"
						id="call-setting"
						name="call"
						value={settings && settings.length > 0 && getSettingValue("call")}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<MenuItem value="enabled"> {i18n.t("settings.settings.call.options.enabled")} </MenuItem>
						<MenuItem value="disabled"> {i18n.t("settings.settings.call.options.disabled")} </MenuItem>
					</Select>
				</Paper>

				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.CheckMsgIsGroup.name")}
					</Typography>

					<Select
						margin="dense"
						variant="outlined"
						id="CheckMsgIsGroup-setting"
						name="CheckMsgIsGroup"
						value={settings && settings.length > 0 && getSettingValue("CheckMsgIsGroup")}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<MenuItem value="enabled"> {i18n.t("settings.settings.CheckMsgIsGroup.options.enabled")} </MenuItem>
						<MenuItem value="disabled"> {i18n.t("settings.settings.CheckMsgIsGroup.options.disabled")} </MenuItem>
					</Select>
				</Paper>

				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.closeTicketApi.name")}
					</Typography>

					<Select
						margin="dense"
						variant="outlined"
						id="closeTicketApi"
						name="closeTicketApi"
						value={settings && settings.length > 0 && getSettingValue("closeTicketApi")}
						className={classes.settingOption}
						onChange={handleChangeSetting}
					>
						<MenuItem value="enabled"> {i18n.t("settings.settings.closeTicketApi.options.enabled")} </MenuItem>
						<MenuItem value="disabled"> {i18n.t("settings.settings.closeTicketApi.options.disabled")} </MenuItem>
					</Select>
				</Paper>

				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.typechatbot.name")}
					</Typography>

					<FormControl className={classes.settingOption} component="fieldset">
						<RadioGroup
							aria-label="chatBotType"
							name="chatBotType"
							value={settings && settings.length > 0 && getSettingValue("chatBotType")}
							onChange={handleChangeSetting}
						>
							<div style={{ display: "flex", flexDirection: "row" }}>
								<Tooltip title={i18n.t("settings.settings.typechatbot.notewhatsappenabled")} placement="top" arrow>
									<FormControlLabel
										value="text"
										control={<Radio color="primary" />}
										label={i18n.t("settings.settings.typechatbot.options.text")}
									/>
								</Tooltip>

								<Tooltip title={i18n.t("settings.settings.typechatbot.notewhatsappdisabled")} placement="top" arrow>
									<FormControlLabel
										value="button"
										disabled
										control={<Radio color="primary" />}
										label={i18n.t("settings.settings.typechatbot.options.button")}
									/>
								</Tooltip>

								<Tooltip title={i18n.t("settings.settings.typechatbot.notewhatsappdisabled")} placement="top" arrow>
									<FormControlLabel
										value="list"
										disabled
										control={<Radio color="primary" />}
										label={i18n.t("settings.settings.typechatbot.options.list")}
									/>
								</Tooltip>
							</div>
						</RadioGroup>
					</FormControl>
				</Paper>

				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.reboot.name")}
					</Typography>

					<Button
						className={classes.settingOption}
						variant="contained"
						color="primary"
						onClick={restartpm2}
					>
						{i18n.t("settings.settings.reboot.button")}
					</Button>
				</Paper>

				<Paper className={classes.paper}>
					<Typography variant="body1">
						{i18n.t("settings.settings.update.name")}
					</Typography>

					<Button
						className={classes.settingOption}
						variant="contained"
						color="primary"
						onClick={updatesoftware}
					>
						{i18n.t("settings.settings.update.button")}
					</Button>
				</Paper>

				<Grid spacing={3} container>
					<Tabs
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="on"
						variant="scrollable"
						className={classes.tab}
						style={{
							marginBottom: 20,
							marginTop: 20
						}}
					>
						<Tab label={i18n.t("settings.settings.integrations.name")} />
					</Tabs>
				</Grid>

				{/*-----------------IXC-----------------*/}
				<Grid spacing={3} container
					style={{ marginBottom: 10 }}>
					<Tabs
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="on"
						variant="scrollable"
						className={classes.tab}
					>
						<Tab label={i18n.t("settings.settings.integrations.ixc.name")} />
					</Tabs>

					<Grid xs={12} sm={6} md={6} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="ipixc"
								name="ipixc"
								margin="dense"
								label={i18n.t("settings.settings.integrations.ixc.ip")}
								variant="outlined"
								value={settings && settings.length > 0 && getSettingValue("ipixc")}
								onChange={handleChangeSetting}
								fullWidth
							/>
						</FormControl>
					</Grid>

					<Grid xs={12} sm={6} md={6} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="tokenixc"
								name="tokenixc"
								label={i18n.t("settings.settings.integrations.ixc.token")}
								margin="dense"
								variant="outlined"
								onChange={handleChangeSetting}
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("tokenixc")}
							/>
						</FormControl>
					</Grid>
				</Grid>

				{/*-----------------MK-AUTH-----------------*/}
				<Grid spacing={3} container
					style={{ marginBottom: 10 }}>
					<Tabs
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="on"
						variant="scrollable"
						className={classes.tab}
					>
						<Tab label={i18n.t("settings.settings.integrations.mkauth.name")} />
					</Tabs>

					<Grid xs={12} sm={12} md={4} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="ipmkauth"
								name="ipmkauth"
								margin="dense"
								label={i18n.t("settings.settings.integrations.mkauth.ip")}
								variant="outlined"
								value={settings && settings.length > 0 && getSettingValue("ipmkauth")}
								onChange={handleChangeSetting}
								fullWidth
							/>
						</FormControl>
					</Grid>

					<Grid xs={12} sm={12} md={4} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="clientidmkauth"
								name="clientidmkauth"
								label={i18n.t("settings.settings.integrations.mkauth.clientid")}
								margin="dense"
								variant="outlined"
								onChange={handleChangeSetting}
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("clientidmkauth")}
							/>
						</FormControl>
					</Grid>

					<Grid xs={12} sm={12} md={4} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="clientsecretmkauth"
								name="clientsecretmkauth"
								label={i18n.t("settings.settings.integrations.mkauth.clientsecret")}
								margin="dense"
								onChange={handleChangeSetting}
								variant="outlined"
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("clientsecretmkauth")}
							/>
						</FormControl>
					</Grid>
				</Grid>

				{/*-----------------VIGO-----------------*/}
				<Grid spacing={3} container
					style={{ marginBottom: 10 }}>
					<Tabs
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="on"
						variant="scrollable"
						className={classes.tab}
					>
						<Tab label={i18n.t("settings.settings.integrations.vigo.name")} />
					</Tabs>

					<Grid xs={12} sm={12} md={4} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="urlvigo"
								name="urlvigo"
								margin="dense"
								label={i18n.t("settings.settings.integrations.vigo.url")}
								variant="outlined"
								value={settings && settings.length > 0 && getSettingValue("urlvigo")}
								onChange={handleChangeSetting}
								fullWidth
							/>
						</FormControl>
					</Grid>

					<Grid xs={12} sm={12} md={4} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="loginvigo"
								name="loginvigo"
								label={i18n.t("settings.settings.integrations.vigo.login")}
								margin="dense"
								variant="outlined"
								onChange={handleChangeSetting}
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("loginvigo")}
							/>
						</FormControl>
					</Grid>

					<Grid xs={12} sm={12} md={4} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="passwordvigo"
								name="passwordvigo"
								label={i18n.t("settings.settings.integrations.vigo.password")}
								margin="dense"
								onChange={handleChangeSetting}
								variant="outlined"
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("passwordvigo")}
							/>
						</FormControl>
					</Grid>
				</Grid>

				{/*-----------------BEESWEB-----------------*/}
				<Grid spacing={3} container
					style={{ marginBottom: 10 }}>
					<Tabs
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="on"
						variant="scrollable"
						className={classes.tab}
					>
						<Tab label={i18n.t("settings.settings.integrations.beesweb.name")} />
					</Tabs>

					<Grid xs={12} sm={12} md={6} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="emailbeesweb"
								name="emailbeesweb"
								label={i18n.t("settings.settings.integrations.beesweb.login")}
								margin="dense"
								variant="outlined"
								onChange={handleChangeSetting}
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("emailbeesweb")}
							/>
						</FormControl>
					</Grid>

					<Grid xs={12} sm={12} md={6} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="passwordbeesweb"
								name="passwordbeesweb"
								label={i18n.t("settings.settings.integrations.beesweb.password")}
								margin="dense"
								onChange={handleChangeSetting}
								variant="outlined"
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("passwordbeesweb")}
							/>
						</FormControl>
					</Grid>
				</Grid>

				{/*-----------------ASAAS-----------------*/}
				<Grid spacing={3} container
					style={{ marginBottom: 10 }}>
					<Tabs
						indicatorColor="primary"
						textColor="primary"
						scrollButtons="on"
						variant="scrollable"
						className={classes.tab}
					>
						<Tab label={i18n.t("settings.settings.integrations.asaas.name")} />
					</Tabs>

					<Grid xs={12} sm={12} md={12} item>
						<FormControl className={classes.selectContainer}>
							<TextField
								id="asaas"
								name="asaas"
								label={i18n.t("settings.settings.integrations.asaas.token")}
								margin="dense"
								variant="outlined"
								onChange={handleChangeSetting}
								fullWidth
								value={settings && settings.length > 0 && getSettingValue("asaas")}
							/>
						</FormControl>
					</Grid>
				</Grid>
			</Container>
		</div>
	);
};

export default Settings;