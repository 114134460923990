import React, { useEffect } from "react";
import openSocket from "socket.io-client";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(theme => ({
	root: {
		display: "flex",
		alignItems: "center",
		padding: theme.spacing(1),

		justifyContent: "center",
		height: "100vh",
		backgroundColor: "#f2f5f9",
		borderRadius: "15px",
	},
	iframeContainer: {
		width: "90%",
		height: "90%",
		borderRadius: "15px",
		border: "1px solid #f0f0f0",
		boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)",
	},
	iframe: {
		borderRadius: "15px",
		width: "100%",
		height: "100%",
		border: "none",
	}
}));


const ApiDocs = () => {
	const classes = useStyles();

	useEffect(() => {
		const socket = openSocket(process.env.REACT_APP_BACKEND_URL);
		return () => {
			socket.disconnect();
		};
	}, []);

	const back = process.env.REACT_APP_BACKEND_URL;
	const endapi = "/api-docs";
	const urlapi = back.concat(endapi);

	return (
		<div className={classes.root}>
			<div className={classes.iframeContainer}>
				<iframe
					title="APIDocs"
					src={urlapi}
					className={classes.iframe}
				/>
			</div>
		</div>
	);
};

export default ApiDocs;